import "normalize-css/normalize.css";
import "../scss/style.scss";

window.addEventListener("load", function (event) {
  /** Main Nav **/
  const mainNavExpandBtn = document.getElementById("main-nav-expand");
  mainNavExpandBtn.onclick = function (e) {
    mainNavExpandBtn.classList.toggle("open");
  };
});
